import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import * as S from "./style";
import { metaLoginHandler } from "utils/requests/other";

const MetaLogin = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const socialId = searchParams.get("socialId") ?? "";
  const metricId = searchParams.get("metricId") ?? "";

  const patchMetaToken = async () => {
    await metaLoginHandler({
      socialId: socialId,
      metricId: metricId
    }).then((res) => {
      if (!res.message) {
        navigate("/dashboard");
      }
    });
  };

  useEffect(() => {
    if (socialId && metricId) {
      patchMetaToken();
    }
  }, [socialId, metricId]);

  return (
    <>
      <S.Background container>
        <S.Loader />
      </S.Background>
    </>
  );
};
export default MetaLogin;
