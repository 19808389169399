export let REACT_APP_API_URL = process.env.REACT_APP_API_URL;
if (!process.env.REACT_APP_API_URL) REACT_APP_API_URL = process.env.REACT_APP_API_DEV_URL

export const getProducts = async (page, filters) => {
  const token = localStorage.getItem("token");
  const tagsQuery = filters?.categories?.length > 0 ? `&${filters.categories.map(tag => `categories[]=${tag}`).join('&')}` : '';
  const response = await fetch(
    REACT_APP_API_URL 
    + 
    encodeURI(
      `/products?statusOrder=DESC&page=${page}&size=10&type[]=INFLUENCER${tagsQuery}${filters?.name ? 
        `&username=${filters?.name}` : ''}`), {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
  });
  const data = await response.json();
  return data;
};

export const getProductsCategories = async () => {
  const token = localStorage.getItem("token");
  const response = await fetch(REACT_APP_API_URL + encodeURI(`/categories?page=0&size=15`), {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
  });
  const data = await response.json();
  return data[0];
};

export const getProductsCreators = async () => {
  const token = localStorage.getItem("token");
  const response = await fetch(REACT_APP_API_URL + encodeURI(`/creators?page=0&size=15`), {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
  });
  const data = await response.json();
  return data[0];
};

export const deleteProduct = async (id) => {
  const token = localStorage.getItem("token");
  const response = await fetch(REACT_APP_API_URL + `/products/${id}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
  });
  const data = await response.json();
  return data;
};

export const editProductStatus = async (id, edit) => {
  const token = localStorage.getItem("token");
  const response = await fetch(REACT_APP_API_URL + `/products/${id}`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify(edit),
  });
  const data = await response.json();
  return data;
};

export const editProduct = async (product, edit) => {
  const token = localStorage.getItem("token");
  const response = await fetch(REACT_APP_API_URL + `/products/${product.id}`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify(edit),
  });
  const data = await response.json();
  return data;
};

export const createProduct = async (productInfo) => {
  const token = localStorage.getItem("token");
  const response = await fetch(REACT_APP_API_URL + `/products`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify(productInfo),
  });
  const data = await response.json();
  return data;
};

export const editProductPhoto = async (edit) => {
  const formData = new FormData();
  Object.entries(edit).forEach(entry => {
    const isArray = Array.isArray(entry[1]);
    if (isArray) {
      entry[1].forEach((str, index) => {
        formData.append(`${entry[0]}[${index}]`, str);
      })
      return
    }
    formData.append(entry[0], entry[1]);
  });
  const token = localStorage.getItem("token");
  const response = await fetch(REACT_APP_API_URL + ``, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`
    },
    body: formData,
  });
  const data = await response.json();
  return data;
};